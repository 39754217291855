import React, { useState } from 'react';

const StateCard = ({ stateName, temperatures, globalIce, requiresIce }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const topTemp = Math.max(...temperatures);
    const bottomTemp = Math.min(...temperatures);

    return (
        <div className={`state-card ${globalIce || requiresIce ? 'ICE' : 'NOICE'}`}>
            <button onClick={toggleVisibility}>
                {stateName.toUpperCase()}: {globalIce || requiresIce ? 'ICE' : 'NO ICE'}
            </button>

            {isVisible && (
                <div className="temperature-details">
                    <p>Top Temperature During Travel: {topTemp}°C</p>
                    <p>Bottom Temperature During Travel: {bottomTemp}°C</p>
                </div>
            )}
        </div>
    );
};

export default StateCard;
