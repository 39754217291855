import React from 'react';
import TemperatureChecker from './components/TemperatureChecker';
import logo from './assets/logo.png'; // Import the logo
import './index.css'; // Import your CSS

function App() {
    const states = [
        'New South Wales', 'Victoria', 'Queensland', 'Western Australia',
        'South Australia', 'Tasmania', 'Northern Territory', 'Australian Capital Territory'
    ];

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="Company Logo" className="App-logo" />
                <h1>Chocolate Shipping Dashboard</h1>
            </header>
            <div className="content">
                {/* Removed Map component */}
                <TemperatureChecker states={states} />
            </div>
        </div>
    );
}

export default App;
