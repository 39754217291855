export const getWeatherData = async (state, latitude, longitude) => {
    try {
        const response = await fetch(
            `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&daily=temperature_2m_max&timezone=Australia/Sydney`
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch weather data: ${response.statusText}`);
        }

        const data = await response.json();

        if (!data.daily || !data.daily.temperature_2m_max) {
            throw new Error('Unexpected API response structure');
        }

        // Check if any temperature exceeds 27°C
        const exceeds27 = data.daily.temperature_2m_max.some(temp => temp > 27);

        // Check if this is NSW and any temperature exceeds 30°C
        const isNSW = state === 'New South Wales';
        const exceeds30InNSW = isNSW && data.daily.temperature_2m_max.some(temp => temp > 30);

        return {
            state,
            requiresIce: exceeds27,
            exceeds30InNSW,
            temperatures: data.daily.temperature_2m_max, // Include the temperature data
        };
    } catch (error) {
        console.error('Error fetching weather data:', error);
        return null;
    }
};
