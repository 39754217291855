import React, { useState, useEffect } from 'react';
import { getWeatherData } from '../services/weatherService';
import StateCard from './StateCard';

const stateCoordinates = {
    'New South Wales': { latitude: -33.8688, longitude: 151.2093 },
    'Victoria': { latitude: -37.8136, longitude: 144.9631 },
    'Queensland': { latitude: -27.4698, longitude: 153.0251 },
    'Western Australia': { latitude: -31.9505, longitude: 115.8605 },
    'South Australia': { latitude: -34.9285, longitude: 138.6007 },
    'Tasmania': { latitude: -42.8821, longitude: 147.3272 },
    'Northern Territory': { latitude: -12.4634, longitude: 130.8456 },
    'Australian Capital Territory': { latitude: -35.2809, longitude: 149.1300 },
};

function TemperatureChecker({ states }) {
    const [results, setResults] = useState([]);
    const [globalIce, setGlobalIce] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const fetchedData = await Promise.all(states.map(async (state) => {
                const { latitude, longitude } = stateCoordinates[state];
                const weatherData = await getWeatherData(state, latitude, longitude);
                if (weatherData) {
                    return {
                        ...weatherData,
                        temperatures: weatherData.temperatures,
                    };
                }
                return null;
            }));
            const validData = fetchedData.filter(data => data !== null);

            const nsExceeds30 = validData.some(data => data.exceeds30InNSW);
            setGlobalIce(nsExceeds30);

            setResults(validData);
        }
        fetchData();
    }, [states]);

    return (
        <div className="TemperatureChecker">
            {results.map(result => (
                <StateCard
                    key={result.state}
                    stateName={result.state}
                    temperatures={result.temperatures} // Pass the temperatures array
                    globalIce={globalIce}
                    requiresIce={result.requiresIce}
                />
            ))}
        </div>
    );
}

export default TemperatureChecker;
